<template>
  <NavCheckAndConfirm :text="executorsDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'ExecutorsCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    backTo() {
      if (this.details.executor_professional) return '/executors/type_of'
      else if (this.details.executor_professional_and_family)
        return '/executors/primary'
      else if (!this.partner && this.details.executors_secondary_required)
        return '/executors/secondary'
      else if (!this.partner && !this.details.executors_secondary_required)
        return '/executors/secondary_option'
      else if (this.partner && this.details.executors_backup_required)
        return '/executors/backup'
      else if (
        !this.details.executors_backup_required &&
        this.partner &&
        this.partner.executor_sole_primary
      )
        return '/executors/backup_option'
      return '/executors/secondary'
    },
    professionalExecutorsAndFamily() {
      return this.$store.getters.details.executor_professional_and_family
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('savePeople')
    this.$store.dispatch('saveDetails')
    this.$notification.success('Executors Saved')
    next()
  }
}
</script>

<style></style>
